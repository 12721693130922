import {
	IBlockData,
	BasicType,
	components,
	createCustomBlock,
	getPreviewClassName,
	AdvancedType,
} from "easy-email-core";

import { CustomBlocksType } from "../constants";
import { merge } from "lodash";
import { getContentEditableClassName } from "easy-email-editor";

const { Column, Group, Section, Wrapper, Text, Button } = components;
type CustomSignatureProps = {
	practiceName: string,
	email: string,
	address1: string,
	city: string,
	state: string,
	zip: string,
	phone: string,
	website: string,
	textColor: string,
};

export const PracticeSignature = createCustomBlock({
	name: "Practice signature",
	type: CustomBlocksType.PRACTICE_SIGNATURE,
	validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER, AdvancedType.TEXT],
    // Enhance this to use mjml component so that can be scaled and previewed through mergeTags approach
	create: (payload) => {
		const defaultData = {
			type: CustomBlocksType.PRACTICE_SIGNATURE,
			data: {
				value: {
					practiceName: "The Doctors & Staff at Practice Name",
					email: "email@practice.com",
					address1: "Practice Address 1",
					city: "Practice City",
					state: "Practice State",
					zip: "Practice ZIP",
					phone: "Practice Phone",
					website: "Practice Website",
				},
			},
			attributes: {
				"text-color": "#333333",
			},
			children: [
				{
					type: AdvancedType.TEXT,
					children: [],
					data: {
						value: {
							content: "Practice Signature",
						},
					},
					attributes: {},
				},
			],
		};
		return merge(defaultData, payload);
	},
	render: ({ data, idx, mode }) => {
		// const { practiceName, email, address1, city, state, zip, phone, website } =
		// 	data.data.value;

		const { practiceName, email, address1, city, state, zip, phone, website } =
			data.data.value;

		const attributes = data?.attributes;

		return (
			<Wrapper
				css-class={
					mode === "testing" ? getPreviewClassName(idx, data?.type) : ""
				}
				padding="20px 0px 20px 0px"
				border="none"
				direction="ltr"
				text-align="left" // Align text to the left
				background-color={attributes["background-color"]} // Apply background color
			>
				<Section padding="0px">
					<Text
						padding="10px 25px 10px 25px"
						align="left"
						color={attributes["text-color"]}
						css-class={getContentEditableClassName(
							BasicType.TEXT,
							`${idx}.data.value`
						).join(" ")}
					>
						{`${practiceName} </br> ${email} </br> ${address1}  </br> ${city} ${state} ${zip} </br> ${phone} </br> ${website}`}
					</Text>
				</Section>
			</Wrapper>
		);
	},
});

export { Panel } from "./panel";
