import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	TextField,
	Autocomplete,
	DialogTitle,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	DialogActions,
	DialogContent,
	DialogContentText,
	Snackbar,
	Stack,
	CircularProgress,
} from "@mui/material";
import { LoadingButton, MobileDateTimePicker } from "@mui/lab";
import ColorSinglePicker from "../../components/ColorSinglePicker";
import axios from "../../utils/axios";

const COLOR_OPTIONS = [
	"#00AB55",
	"#1890FF",
	"#94D82D",
	"#FFC107",
	"#FF4842",
	"#04297A",
	"#7A0C2E",
];

// Fetch practices based on search query
const fetchPractices = async (query) => {
	try {
		const response = await axios.get("/campaign/getAllPractices", {
			params: { page: 1, limit: 50, search: query },
		});
		return response.data.data;
	} catch (error) {
		console.error("Error fetching practices:", error);
		return [];
	}
};

// Fetch a specific practice by ID
const fetchPracticeById = async (practiceId) => {
	try {
		const response = await axios.get(
			`/campaign/getPracticeDetails?practiceId=${practiceId}`
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching practice by ID:", error);
		return null;
	}
};

// Fetch campaigns based on search query
const fetchCampaigns = async (query) => {
	try {
		const response = await axios.get(`/campaign/getAllLegacyCampaigns`, {
			params: { search: query },
		});
		return response.data.data;
	} catch (error) {
		console.error("Error fetching campaigns:", error);
		return [];
	}
};

// Fetch a specific campaign by ID
const fetchCampaignById = async (legacyId) => {
	try {
		const response = await axios.get(
			`/campaign/getLegacyCampaignTypeDetails?legacyId=${legacyId}`
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching campaign by ID:", error);
		return null;
	}
};

const NewCampaignDialog = ({
	open,
	onClose,
	onSubmit,
	initialValues = {
		name: "",
		type: "",
		practiceId: "",
		legacyId: "",
		scheduledDate: new Date(),
		color: "#1890FF",
	},
	isEdit = false,
}) => {
	const [loading, setLoading] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [formValues, setFormValues] = useState(initialValues || {});
	const [practices, setPractices] = useState([]);
	const [selectedPractice, setSelectedPractice] = useState(null);
	const [practiceSearchQuery, setPracticeSearchQuery] = useState("");
	const [practiceLoading, setPracticeLoading] = useState(false);
	const [campaigns, setCampaigns] = useState([]);
	const [selectedCampaign, setSelectedCampaign] = useState(null);
	const [campaignSearchQuery, setCampaignSearchQuery] = useState("");
	const [campaignLoading, setCampaignLoading] = useState(false);

	useEffect(() => {
		setFormValues(initialValues);

		if (!isEdit) {
			setSelectedPractice(null);
			setFormValues((prev) => ({ ...prev, practiceId: "" }));
			setSelectedCampaign(null);
			setFormValues((prev) => ({ ...prev, legacyId: "" }));
		}

		const loadInitialPractice = async () => {
			if (isEdit && initialValues.practiceId) {
				setPracticeLoading(true);
				const fetchedPractice = await fetchPracticeById(
					initialValues.practiceId
				);
				setSelectedPractice(fetchedPractice);
				setPracticeLoading(false);
			}
		};

		const loadInitialCampaign = async () => {
			if (isEdit && initialValues.legacyId) {
				setCampaignLoading(true);
				const fetchedCampaign = await fetchCampaignById(initialValues.legacyId);
				setSelectedCampaign(fetchedCampaign);
				setCampaignLoading(false);
			}
		};

		if (isEdit) {
			loadInitialPractice();
			loadInitialCampaign();
		}
	}, [initialValues, isEdit]);

	// Fetch practices whenever the search query changes
	useEffect(() => {
		const loadPractices = async () => {
			setPracticeLoading(true);
			const fetchedPractices = await fetchPractices(practiceSearchQuery);
			setPractices(fetchedPractices);
			setPracticeLoading(false);
		};

		if (practiceSearchQuery.length > 2) {
			loadPractices();
		} else {
			setPractices([]);
		}
	}, [practiceSearchQuery]);

	// Fetch campaigns whenever the search query changes
	useEffect(() => {
		const loadCampaigns = async () => {
			setCampaignLoading(true);
			const fetchedCampaigns = await fetchCampaigns(campaignSearchQuery);
			setCampaigns(fetchedCampaigns);
			setCampaignLoading(false);
		};

		if (campaignSearchQuery.length) {
			loadCampaigns();
		} else {
			setCampaigns([]);
		}
	}, [campaignSearchQuery]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};

	const handleDateChange = (date) => {
		setFormValues({ ...formValues, scheduledDate: date });
	};

	const handleColorChange = (event) => {
		setFormValues({ ...formValues, color: event.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Basic validation to check if required fields are filled
		const { name, type, practiceId, legacyId, scheduledDate, color } =
			formValues;

		if (
			!name ||
			!type ||
			!practiceId ||
			!legacyId ||
			!scheduledDate ||
			!color
		) {
			setSnackbarMessage("Please fill in all required fields.");
			return;
		}

		setLoading(true);
		try {
			await onSubmit(formValues, isEdit);
		} catch (error) {
			setSnackbarMessage(
				isEdit
					? "Failed to update the campaign"
					: "Failed to create the campaign"
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Dialog open={open} onClose={onClose} fullWidth>
				<DialogTitle>
					{isEdit ? "Edit Campaign" : "Create New Campaign"}
				</DialogTitle>
				<form autoComplete="off" noValidate onSubmit={handleSubmit}>
					<DialogContent>
						<DialogContentText>
							{isEdit
								? "Edit the details of your campaign."
								: "Fill in the basic information for your new campaign."}
						</DialogContentText>
						<Stack spacing={3} sx={{ p: 3 }}>
							<FormControl fullWidth>
								<TextField
									label="Campaign Name"
									name="name"
									value={formValues.name}
									onChange={handleInputChange}
									required
								/>
							</FormControl>

							<FormControl fullWidth required>
								<Autocomplete
									options={practices}
									getOptionLabel={(option) => option.name || ""}
									loading={practiceLoading}
									value={selectedPractice}
									onInputChange={(event, value) =>
										setPracticeSearchQuery(value)
									}
									onChange={(event, newValue) => {
										setSelectedPractice(newValue);
										setFormValues({
											...formValues,
											practiceId: newValue?.practiceId || "",
										});
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Select Practice"
											variant="outlined"
											required
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{practiceLoading ? (
															<CircularProgress color="inherit" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									)}
								/>
							</FormControl>

							<FormControl fullWidth required>
								<Autocomplete
									options={campaigns}
									getOptionLabel={(option) => option.name} // The main label displayed in the input box
									loading={campaignLoading}
									value={selectedCampaign}
									onInputChange={(event, value) =>
										setCampaignSearchQuery(value)
									}
									onChange={(event, newValue) => {
										setSelectedCampaign(newValue);
										setFormValues({
											...formValues,
											legacyId: newValue?.id || "",
										});
									}}
									// Custom rendering for each option in the dropdown
									renderOption={(props, option) => (
										<li
											{...props}
											style={{
												display: "flex",
												flexDirection: "column",
												padding: "10px",
												borderBottom: "1px solid #eee",
											}}
										>
											<span style={{ fontWeight: "bold" }}>{option.name}</span>
											<span style={{ color: "#888" }}>
												Species: {option.species}
											</span>
											<span style={{ color: "#aaa", fontSize: "12px" }}>
												ID: {option.id}
											</span>
										</li>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Select Campaign"
											variant="outlined"
											required
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{campaignLoading ? (
															<CircularProgress color="inherit" size={20} />
														) : null}
														{params.InputProps.endAdornment}
													</>
												),
											}}
										/>
									)}
								/>
							</FormControl>

							<FormControl fullWidth required>
								<InputLabel>Campaign Type</InputLabel>
								<Select
									name="type"
									value={formValues.type}
									onChange={handleInputChange}
								>
									<MenuItem value="email">Email</MenuItem>
									<MenuItem value="text">Text</MenuItem>
								</Select>
							</FormControl>

							<FormControl fullWidth>
								<MobileDateTimePicker
									label="Scheduled Date"
									value={formValues.scheduledDate}
									inputFormat="dd/MM/yyyy hh:mm a"
									onChange={handleDateChange}
									renderInput={(params) => <TextField {...params} required />}
								/>
							</FormControl>

							<FormControl fullWidth>
								<ColorSinglePicker
									value={formValues.color}
									onChange={handleColorChange}
									colors={COLOR_OPTIONS}
								/>
							</FormControl>
						</Stack>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={onClose}
							variant="contained"
							color="inherit"
							disabled={loading}
						>
							Cancel
						</Button>
						<LoadingButton
							type="submit"
							variant="contained"
							loading={loading}
							loadingIndicator="Loading..."
							disabled={loading}
						>
							{isEdit ? "Save Changes" : "Continue"}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
			<Snackbar
				open={!!snackbarMessage}
				onClose={() => setSnackbarMessage("")}
				message={snackbarMessage}
				autoHideDuration={3000}
			/>
		</>
	);
};

export default NewCampaignDialog;
