import { useState } from "react";
import {
	Box,
	Step,
	Paper,
	Button,
	Stepper,
	StepLabel,
	Typography,
	StepConnector,
	stepConnectorClasses,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import { useMediaQuery } from "@mui/material";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: theme.palette.success.main,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: theme.palette.success.main,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderRadius: 1,
		borderTopWidth: 3,
		borderColor: theme.palette.divider,
	},
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	height: 22,
	display: "flex",
	alignItems: "center",
	color: theme.palette.text.disabled,
	...(ownerState.active && {
		color: theme.palette.success.main,
	}),
	"& .QontoStepIcon-completedIcon": {
		zIndex: 1,
		fontSize: 18,
		color: theme.palette.success.main,
	},
	"& .QontoStepIcon-circle": {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
}));

function QontoStepIcon({ active, completed }) {
	return (
		<QontoStepIconRoot ownerState={{ active }}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

export default function CustomizedVerticalSteppers({ steps }) {
	const [activeStep, setActiveStep] = useState(0);
	const theme = useTheme();
	let isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // isMobile = true;
	const handleNext = () =>
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	const handleBack = () =>
		setActiveStep((prevActiveStep) => prevActiveStep - 1);

	return (
		<Box display="flex" flexDirection={isMobile ? "column" : "row"}>
			<Box>
				<Stepper
					activeStep={activeStep}
					orientation={isMobile ? "horizontal" : "vertical"}
					connector={<QontoConnector />}
				>
					{steps.map(({ label }, index) => (
						<Step key={label}>
							<StepLabel
								StepIconComponent={(props) => (
									<QontoStepIcon
										active={activeStep === index}
										completed={activeStep > index}
										{...props}
									/>
								)}
							>
								{label}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</Box>
			<Box flex={1} ml={isMobile ? 0 : 4} mt={isMobile ? 4 : 0}>
				<Box sx={{ textAlign: "right" }}>
					<Button
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
					>
						Back
					</Button>
					<Button variant="contained" onClick={handleNext} sx={{ mr: 1 }}>
						{activeStep === steps.length - 1 ? "Finish" : "Next"}
					</Button>
				</Box>
				<Paper sx={{ p: 3, my: 3, minHeight: 120, bgcolor: "grey.50012" }}>
					<Typography>{steps[activeStep].content}</Typography>
				</Paper>
			</Box>
		</Box>
	);
}
