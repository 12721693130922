import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Page from "../../components/Page";
import CustomizedVerticalSteppers from "../components-overview/material-ui/stepper/CustomizedVerticalStepper";
import CampaignList from "../../components/_dashboard/general-app/CampaignList";
import SelectPractice from "./SelectPractice";
import { EasyEmailEditor } from "../../components/email-editor";
import EmailPreview from "../../components/email-editor/EmailPreview";
import CampaignEmailSettings from "../../components/campaign/campaignEmailSettings";
import { useNavigate, useParams } from "react-router";
import NewCampaignDialog from "./NewCampaignDialog";
import axios from "../../utils/axios";
import TemplateSelector from "./TemplateSelector";
import LiveEmailEditor from "../../components/email-editor/LiveEmailEditor";
import TestCampaign from "./TestCampaign";
import CampaignFileUpload from "./CampaignFileUpload";

export default function CampaignStepper() {
	const [campaign, setCampaign] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const { campaignId } = useParams();
	const [selectedPracticeId, setSelectedPracticeId] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		if (campaignId) {
			fetchCampaign();
		}
	}, [campaignId]);

	const fetchCampaign = async () => {
		setIsLoading(true);
		try {
			const response = await axios.get(`/campaign/${campaignId}`);
			setCampaign(response.data);
		} catch (error) {
			console.error("Failed to fetch campaign:", error);
		} finally {
			setIsLoading(false);
		}
	};

	async function sendTestMessage(recipient) {
		const payload = { recipient, campaignId: campaign.id };
		const response = await axios.post(
			`/campaign/test-campaign/${campaignId}`,
			payload
		);
		return response.data;
	}

	const handleUpdateCampaign = async (updatedData) => {
		setIsLoading(true);
		try {
			await axios.put(`/campaign/${campaignId}`, updatedData);
			setCampaign({ ...campaign, ...updatedData });
		} catch (error) {
			console.error("Failed to update campaign:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const steps = [
		{
			label: "Select template",
			content: (
				<TemplateSelector
					onSelect={(templateObj) => handleUpdateCampaign(templateObj)}
				/>
			),
		},
        {
			label: "Select campaign Data",
			content: <CampaignFileUpload />,
		},
		{
			label: "Enhance campaign",
			content: campaign.emailJson && (
				<LiveEmailEditor
					json={JSON.parse(campaign.emailJson)}
					onUpdate={({ json, html }) =>
						handleUpdateCampaign({ emailJson: json, emailHtml: html })
					}
				/>
			),
		},
		{
			label: "Preview",
			content: (
				<EmailPreview
					practiceId={campaign.practiceId}
					emailHtml={campaign.emailHtml}
				/>
			),
		},
		{
			label: "Test Campaign",
			content: <TestCampaign onTestSend={sendTestMessage} />,
		},
	];

	return (
		<Page>
			<>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 2,
					}}
				>
					<Typography variant="h4">Campaigns</Typography>
				</Box>
				<CustomizedVerticalSteppers steps={steps} isLoading={isLoading} />
			</>
		</Page>
	);
}
