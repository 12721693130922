import {
    IBlockData,
    BasicType,
    components,
    createCustomBlock,
    getPreviewClassName,
    AdvancedType,
} from "easy-email-core";

import { CustomBlocksType } from "../constants";
import { merge } from "lodash";

const { Section, Wrapper, Image } = components;

export const PracticeLogo = createCustomBlock({
    name: "Practice Logo",
    type: CustomBlocksType.PRACTICE_LOGO,
    validParentType: [BasicType.PAGE, AdvancedType.WRAPPER, BasicType.WRAPPER],
    create: (payload) => {
        const defaultData = {
            type: CustomBlocksType.PRACTICE_LOGO,
            data: {
                value: {
                    logoUrl: "{{practice.logo}}",
                    logoWidth: "300px",
                    logoHeight: "200px",
                    logoAlt: "{{practice.name}}",
                },
            },
            attributes: {
                "background-color": "#FFFFFF",
                "padding": "10px",
            },
            children: [],
        };
        return merge(defaultData, payload);
    },
    render: ({ data, idx, mode }) => {
        const { logoUrl, logoWidth, logoHeight, logoAlt, alignment } = data.data.value;
        const attributes = data?.attributes;
    
        return (
            <Wrapper
                css-class={mode === "testing" ? getPreviewClassName(idx, data?.type) : ""}
                padding={attributes.padding}
                border="none"
                direction="ltr"
                background-color={attributes["background-color"]}
            >
                <Section
                    padding="0px"
                    text-align={alignment}
                >
                    <mj-column>
                        <mj-image
                            align="center"
                            height={logoHeight}
                            padding="0px 0px 0px 0px"
                            width={logoWidth}
                            src={logoUrl}
                            alt={logoAlt}
                        />
                    </mj-column>
                </Section>
            </Wrapper>
        );
    },
    });

export { Panel } from "./panel";