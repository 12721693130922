import React, { useState, useEffect, useRef } from "react";
import { Container, Paper, IconButton, Toolbar, Tooltip } from "@mui/material";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import axios from "../../utils/axios"; // Adjust the import path as needed
import Handlebars from "handlebars";

const EmailPreview = ({
	desktopWidth = 800,
	desktopHeight = 600,
	mobileWidth = 375,
	mobileHeight = 667,
	emailHtml,
	practiceId,
}) => {
	const isSmallScreen = window.innerWidth < 600;
	const [isMobileView, setIsMobileView] = useState(isSmallScreen);
	const iframeRef = useRef(null);
	const [renderedHtml, setRenderedHtml] = useState("");

	const fetchMergeTags = async () => {
		try {
			const logoResponse = await axios.get(
				`/campaign/getPracticeLogo?practiceId=${practiceId}`
			);
            const response = await axios.get(
				`/campaign/getPracticeDetails?practiceId=${practiceId}`
			);

			const mergeTags = {
				practice: { ...response.data,
                    logo: logoResponse.data
                },
			};

			const template = Handlebars.compile(emailHtml);
			const renderedHtml = template(mergeTags);
            console.log(renderedHtml);
			setRenderedHtml(renderedHtml);
		} catch (error) {
			console.error("Failed to fetch practice details:", error);
		}
	};

	useEffect(() => {
		fetchMergeTags();
	}, []);

	useEffect(() => {
		const iframeDocument = iframeRef.current.contentDocument;
		iframeDocument.open();
		iframeDocument.write(renderedHtml);
		iframeDocument.close();
	}, [renderedHtml]);

	const iframeWidth = isMobileView ? mobileWidth : desktopWidth;
	const iframeHeight = isMobileView ? mobileHeight : desktopHeight;

	return (
		<Container
			maxWidth="md"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "80vh",
			}}
		>
			<Paper elevation={3} style={{ padding: "20px", borderRadius: "10px" }}>
				<Toolbar variant="dense" style={{ justifyContent: "flex-end" }}>
					{!isSmallScreen && (
						<Tooltip
							title={
								isMobileView
									? "Switch to Desktop View"
									: "Switch to Mobile View"
							}
						>
							<IconButton
								edge="end"
								color="primary"
								onClick={() => setIsMobileView(!isMobileView)}
							>
								{isMobileView ? (
									<DesktopAccessDisabledIcon />
								) : (
									<MobileFriendlyIcon />
								)}
							</IconButton>
						</Tooltip>
					)}
				</Toolbar>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: iframeHeight,
					}}
				>
					<Paper
						elevation={0}
						variant="outlined"
						style={{
							width: iframeWidth,
							height: iframeHeight,
							overflow: "auto",
						}}
					>
						<iframe
							title="Email Preview"
							src="about:blank"
							width="100%"
							height="100%"
							frameBorder="0"
							scrolling="yes"
							ref={iframeRef}
						/>
					</Paper>
				</div>
			</Paper>
		</Container>
	);
};

export default EmailPreview;
