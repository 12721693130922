import { Stack } from "@mui/material";
import { useFocusIdx } from "easy-email-editor";
import {
	AttributesPanelWrapper,
	ColorPickerField,
	TextField,
} from "easy-email-extensions";
import React from "react";

export function Panel() {
	const { focusIdx } = useFocusIdx();
	return (
		<AttributesPanelWrapper style={{ padding: "20px" }}>
			<Stack>
				<TextField
					label="Email"
					name={`${focusIdx}.data.value`}
					inline
                    font=""
					alignment="center"
				/>
				<TextField
					label="Address Line 1"
					name={`${focusIdx}.data.value.address1`}
					inline
					alignment="center"
				/>
				<TextField
					label="City"
					name={`${focusIdx}.data.value.city`}
					inline
					alignment="center"
				/>
				<TextField
					label="State"
					name={`${focusIdx}.data.value.state`}
					inline
					alignment="center"
				/>
				<TextField
					label="ZIP"
					name={`${focusIdx}.data.value.zip`}
					inline
					alignment="center"
				/>
				<TextField
					label="Phone"
					name={`${focusIdx}.data.value.phone`}
					inline
					alignment="center"
				/>
				<TextField
					label="Website"
					name={`${focusIdx}.data.value.website`}
					inline
					alignment="center"
				/>
				<ColorPickerField
					label="Text color"
					name={`${focusIdx}.attributes.text-color`}
					inline
					alignment="center"
				/>
				<ColorPickerField
					label="Background color"
					name={`${focusIdx}.attributes.background-color`}
					inline
					alignment="center"
				/>
			</Stack>
		</AttributesPanelWrapper>
	);
}
