import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import axiosInstance from "../../utils/axios";
import { useSnackbar } from "notistack";

const CampaignFileUpload = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [fileName, setFileName] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".csv, .xlsx, .xls", // Only accepting campaign-related data files (CSV or Excel)
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setSelectedFile(file);
        setFileName(file.name);
      }
    },
  });

  const handleFileUpload = async () => {
    if (!selectedFile) {
      enqueueSnackbar("Please select a file first!", { variant: "warning" });
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("name", fileName);

    try {
      const response = await axiosInstance.post("/campaign/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setStatus({
          type: "success",
          message: "File uploaded successfully.",
        });
      } else {
        setStatus({
          type: "error",
          message: "Error uploading the file.",
        });
      }
    } catch (error) {
      setStatus({
        type: "error",
        message: "An error occurred while uploading the file.",
      });
    } finally {
      setLoading(false);
      setSelectedFile(null);
      setFileName("");
    }
  };

  return (
    <Box sx={{ p: 2, bgcolor: "background.paper" }}>
      <Typography variant="h5" gutterBottom>
        Upload Campaign Data
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "200px",
          border: "1px dashed gray",
          p: 2,
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon color="action" style={{ fontSize: 72 }} />
        {selectedFile ? (
          <p>{fileName}</p>
        ) : (
          <Typography variant="body2" color="textSecondary">
            Drag and drop the campaign data file here, or click to select the file
          </Typography>
        )}
      </Box>
      {selectedFile && (
        <TextField
          fullWidth
          margin="normal"
          label="File Name"
          variant="outlined"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
      )}
      {selectedFile && !loading && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleFileUpload}
          sx={{ mt: 2 }}
        >
          Upload File
        </Button>
      )}
      {loading && <CircularProgress />}
      {status && (
        <Alert severity={status.type} sx={{ mt: 2 }}>
          {status.message}
        </Alert>
      )}
    </Box>
  );
};

export default CampaignFileUpload;
