import {
	CircularProgress,
	Container,
	Box,
	Card,
	Button,
	Typography,
} from "@mui/material";
import {
	BlockManager,
	BasicType,
	AdvancedType,
	JsonToMjml,
} from "easy-email-core";
import {
	BlockAvatarWrapper,
	EmailEditor,
	EmailEditorProvider,
} from "easy-email-editor";
import { StandardLayout } from "easy-email-extensions";
import { useWindowSize } from "react-use";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import mjml from "mjml-browser";
import SvgIconStyle from "../../components/SvgIconStyle";
import Code from "@mui/icons-material/CodeRounded";
import { useState, useEffect, useRef, useCallback } from "react";
import { useSnackbar } from "notistack";
import { debounce, isEqual, cloneDeep, set } from "lodash";
import axios from "../../utils/axios";
import { useParams } from "react-router-dom";
import Handlebars from "handlebars";

const getIcon = (name) => (
	<SvgIconStyle
		src={`/static/icons/navbar/${name}.svg`}
		sx={{ width: "100%", height: "100%" }}
	/>
);

const categories = [
	{
		label: "Custom",
		active: true,
		displayType: "custom",
		blocks: [
			<BlockAvatarWrapper type={BasicType.PRACTICE_SIGNATURE}>
				<Box sx={{ p: 1 }}>
					<Card
						sx={{
							overflow: "auto",
							borderRadius: 0,
							p: 1,
							width: 100,
							height: 110,
						}}
					>
						<Box sx={{ p: 1, textAlign: "center" }}>
							<Code />
							<Typography variant="body2">{"Practice Signature"}</Typography>
						</Box>
					</Card>
				</Box>
			</BlockAvatarWrapper>,
		],
	},
	{
		label: "Content",
		active: true,
		blocks: [
			{ type: AdvancedType.TEXT },
			{ type: AdvancedType.BUTTON },
			{ type: AdvancedType.SOCIAL },
			{ type: AdvancedType.DIVIDER },
			{ type: AdvancedType.SPACER },
			{ type: AdvancedType.HERO },
			{ type: AdvancedType.NAVBAR },
			{ type: AdvancedType.ACCORDION },
			{ type: AdvancedType.CAROUSEL },
			{ type: AdvancedType.WRAPPER },
			{ type: AdvancedType.SECTION },
			{ type: AdvancedType.COLUMN },
			{ type: AdvancedType.GROUP },
		],
	},
	{
		label: "Layout",
		active: true,
		displayType: "column",
		blocks: [
			{
				title: "2 columns",
				payload: [
					["50%", "50%"],
					["33%", "67%"],
					["67%", "33%"],
					["25%", "75%"],
					["75%", "25%"],
				],
			},
			{
				title: "3 columns",
				payload: [
					["33.33%", "33.33%", "33.33%"],
					["25%", "25%", "50%"],
					["50%", "25%", "25%"],
				],
			},
			{ title: "4 columns", payload: [["25%", "25%", "25%", "25%"]] },
		],
	},
];

const initialValues = {
	subject: "Welcome to Verticalvet Connect",
	subTitle: "Nice to meet you!",
	content: BlockManager.getBlockByType(BasicType.PAGE).create({}),
};

export default function LiveEmailEditor({ json, onUpdate }) {
	const { width } = useWindowSize();
	const { themeStretch } = useSettings();
	const { enqueueSnackbar } = useSnackbar();
	const { practiceId } = useParams(); // Assuming practiceId is passed as a route param
	const [editorContent, setEditorContent] = useState(json || initialValues);
	const previousValuesRef = useRef(editorContent);
	const [isSaving, setIsSaving] = useState(false);
	const [mergeTags, setMergeTags] = useState({
		practice: {
			name: "Daniel Island Animal Hospital",
			website: "www.danielislandvet.com",
			address1: "291 Seven Farms Dr Ste 103",
			address2: null,
			city: "Charleston",
			state: "SC",
			zip: "29492",
			phone: "843-881-7228",
			logo: "RT_002_DIA_01152024_Logo.pdf",
			logosm: null,
			color: "#417594",
			emailRemindersReplyTo: "info@danielislandvet.com",
			cmykColor: "55,13,0,44",
		},
	});

	useEffect(() => {
		if (!isEqual(json, previousValuesRef.current)) {
			setEditorContent(json);
			previousValuesRef.current = json;
		}
	}, [json]);

	const debouncedSave = useCallback(
		debounce((values) => {
			if (isSaving) {
				return;
			}

			const mjmlString = JsonToMjml({
				data: values.content,
				mode: "production",
				context: values.content,
				dataSource: mergeTags,
			});

			const payload = {
				json: JSON.stringify(values),
				html: mjml(mjmlString, {}).html,
			};

			setIsSaving(true);

			setTimeout(() => {
				setIsSaving(false);
				previousValuesRef.current = values;
				onUpdate && onUpdate(payload);
				enqueueSnackbar("Saved Successfully", { variant: "success" });
			}, 1000);
		}, 200),
		[isSaving, onUpdate, enqueueSnackbar, mergeTags]
	);

	const handleSave = (values) => {
		if (!isEqual(values, previousValuesRef.current)) {
			debouncedSave(values);
			setEditorContent(values);
		}
	};

	const smallScene = width < 1400;

	return (
		<Page>
			<Container maxWidth={themeStretch ? false : "l"}>
				<EmailEditorProvider
					data={editorContent}
					height={"calc(100vh - 72px)"}
					width={"100%"}
					autoComplete
					dashed={false}
					mergeTags={mergeTags}
					onChangeMergeTag={(path, val) => {
						setMergeTags((old) => {
							const newObj = cloneDeep(old);
							set(newObj, path, val);
							return newObj;
						});
					}}
					mergeTagGenerate={(tag) => `{{${tag}}}`}
					onBeforePreview={(html) => {
						const template = Handlebars.compile(html);
						return template(mergeTags);
					}}
				>
					{({ values }) => {
						return (
							<StandardLayout
								compact={!smallScene}
								categories={categories}
								showSourceCode={false}
							>
								{
									// Hide save button in preview mode
									<Button
										variant="contained"
										color="primary"
										onClick={() => handleSave(values)}
										disabled={isSaving}
										sx={{ margin: 2 }}
									>
										{isSaving ? "Saving..." : "Save"}
									</Button>
								}
								<EmailEditor />
							</StandardLayout>
						);
					}}
				</EmailEditorProvider>
			</Container>
		</Page>
	);
}
